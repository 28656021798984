export default {
  name: 'user',
  component: () =>
    import(
      /* webpackChunkName: "Auth Layout" */ '@/modules/user/layouts/UserLayout.vue'
    ),
  children: [
    {
      path: '',
      name: 'home',
      component: () =>
        import(
          /* webpackChunkName: "Welcome View" */ '@/modules/user/views/home/HomeView.vue'
        ),
    },
    {
      path: 'bienvenido',
      name: 'email-confirmated',
      component: () =>
        import(
          /* webpackChunkName: "Email Confirmated View" */ '@/modules/user/views/email-confirmated/EmailConfirmatedView.vue'
        ),
    },
    {
      path: 'facturacion',
      name: 'dashboard-billing',
      meta:{title:"Facturación"},
      component: () =>
        import(
          /* webpackChunkName: "Email Confirmated View" */ '@/modules/user/views/dashboard-billing/DashboardBillingView.vue'
        ),
    },
    {
      path: 'cuentas',
      name: 'dashboard-accounts',
      meta:{title:"Cuentas corrientes"},
      component: () =>
        import(
          /* webpackChunkName: "Email Confirmated View" */ '@/modules/user/views/dashboard-accounts/DashboardAccountsView.vue'
        ),
    },
    {
      path: 'cobranzas',
      name: 'dashboard-collection',
      meta:{title:"Cobranzas"},
      component: () =>
        import(
          /* webpackChunkName: "Email Confirmated View" */ '@/modules/user/views/dashboard-collection/DashboardCollectionView.vue'
        ),
    },
     {
      path: 'consultas',
      name: 'dashboard-other-requests',
      meta:{title:"Otras consultas"},
      component: () =>
        import(
          /* webpackChunkName: "Email Confirmated View" */ '@/modules/user/views/dashboard-other-requests/DashboardOtherRequestsView.vue'
        ),
    },
    {
      path: 'mi-perfil',
      name: 'dashboard-profile',
      meta:{title:"Mi Perfil"},
      component: () =>
        import(
          /* webpackChunkName: "Email Confirmated View" */ '@/modules/user/views/dashboard-profile/DashboardProfileView.vue'
        ),
    },
    {
      path: 'bienvenido',
      name: 'wellcome',
      meta:{title:"Bienvenido"},
      component: () =>
        import(
          /* webpackChunkName: "Email Confirmated View" */ '@/modules/user/views/wellcome-page/WellcomeView.vue'
        ),
    }
  ],
};
