import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/styles.scss";
import "./modules/auth/styles/styles.scss";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import "./utils/ga/ga";
import gaPage from './utils/ga/gaPage';
import { createI18n } from 'vue-i18n'
import es from './lang/es.json'
import en from './lang/en.json'

const i18n = createI18n({
    locale: localStorage.getItem('locale') || 'es',
    messages:{
        es,
        en
    }
})

createApp(App)
.component('Carousel', Carousel)
.component('Slide', Slide)
.component('Navigation', Navigation)
.use(store)
.use(gaPage)
.use(router)
.use(i18n)
.mount("#app");


